.login-container {
    background-image: url(../images/galleryLoginBg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    .login-div {
        margin: auto;
        text-align: center;
        width: 320px;
        background-color: white;
        padding: 20px;
        height: 400px;
        border-radius: 4px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
}

.input-text-field {
    width: 100%;
    // width: 300px;
    border: 1px solid #b5b5b5;
    height: 35px;
    padding-left: 6px;
    outline: none;
    color: #484848;
    margin: 5px 0px;
    border-radius: 4px;
    font-size: 15px;
    &:focus {
        // outline: 1px solid rgb(184, 184, 255);
        outline: none;
        border: 1px solid #5f5f5f;
    }
}
