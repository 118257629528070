body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.d-f {
    display: flex !important;
}

.m-a {
    margin: auto !important;
}

.m-t-20 {
    margin-top: 20px;
}

.main-loader {
    height: 100vh !important;
    .Loader__background {
        z-index: 9999 !important;
    }
}

p {
    margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}
