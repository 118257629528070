.banner-card {
    // height: 100px;
    border: 1px solid rgb(204, 204, 204);
    padding: 5px;
    border-radius: 4px;
    h4 {
        text-align: center;
        display: flex;
        font-weight: 600;
        margin-top: 5px;
        margin-bottom: 10px;
        svg {
            margin-top: -1px;
            font-size: 1.3rem !important;
        }
        span {
            margin-left: 6px;
            align-self: center;
        }
    }
    .content {
        .video-link {
            height: 100px;
            background-color: rgb(216, 216, 216);
            border-radius: 4px;
            align-self: center;
            display: flex;
            p,
            a {
                margin: auto;
                word-break: break-all;
                padding: 10px;
                &:hover {
                    color: blue;
                }
            }
        }
        .image-div {
            height: 100px;
            img {
                border-radius: 4px;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    // background-color: yellowgreen;
}

.move-add-button-right {
    margin-bottom: 10px !important;
    margin-right: 10px !important;
    button {
        float: right;
        // background: #e41616;
        // color: white;
        text-transform: capitalize;
        &:hover {
            // background: #ff4040;
        }
    }
}

.modal-create-btn {
    margin-right: 10px !important;
    background: #35658e !important;
    color: white !important;
    padding: 5px 16px !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
}

.modal-cancel-btn {
    margin-right: 18px !important;
    padding: 5px 16px !important;
    background: #d8d8d8 !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
}

.category-card {
    padding: 10px;
    .category-card-div {
        border: 1px solid rgb(204, 204, 204);
        padding: 5px;
        border-radius: 4px;
        transition: transform 0.5s;
        cursor: pointer;
        h4 {
            text-align: center;
            font-size: 17px;
            margin-bottom: 10px;
            padding: 0px 5px;
        }
        .image-div {
            height: 220px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 4px;
            }
        }
        &:hover {
            -ms-transform: scale(1.5); /* IE 9 */
            -webkit-transform: scale(1.5); /* Safari 3-8 */
            transform: scale(1.04);
        }
    }
}

.video-link-card {
    padding: 10px;
    .video-link-div {
        border: 1px solid rgb(204, 204, 204);
        padding: 5px;
        border-radius: 4px;
        h4 {
            text-align: center;
            display: flex;
            font-weight: 600;
            margin-top: 5px;
            margin-bottom: 10px;
            svg {
                margin-top: -1px;
                font-size: 1.3rem !important;
            }
            span {
                margin-left: 6px;
                align-self: center;
            }
        }
        .heading-div {
            display: flex;
            justify-content: space-between;
            .featured-p {
                font-weight: 600;
                color: green;
                margin: 5px;
            }
            .featured-div {
                background: #636084;
                color: white;
                height: fit-content;
                padding: 3px 7px;
                margin: 2px;
                font-size: 15px;
                font-weight: 500;
                cursor: pointer;
                border-radius: 4px;
                &:hover {
                    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
                }
            }
        }
        .content {
            .video-link {
                height: 100px;
                background-color: rgb(216, 216, 216);
                border-radius: 4px;
                align-self: center;
                display: flex;
                a {
                    margin: auto;
                    word-break: break-all;
                    padding: 10px;
                    &:hover {
                        color: blue;
                    }
                }
            }
            .image-div {
                height: 100px;
                img {
                    border-radius: 4px;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.video-link-container {
    .heading {
        font-weight: 600;
        font-size: 16px;
        span {
            font-weight: 400;
            // font-style: italic;
            text-transform: uppercase;
        }
    }
}

.featured-card {
    padding: 10px;
    .featured-card-div {
        border: 1px solid rgb(204, 204, 204);
        padding: 5px;
        border-radius: 4px;
    }
}

.notification-container {
    .user-count {
        text-align: right;
        p {
            font-size: 18px;
            span {
                font-weight: 600;
            }
        }
    }
    .notification-details {
        padding-top: 40px;
        display: flex;
        div {
            padding: 10px;
            margin: auto;
        }
        .container {
            .label {
                display: flex;
                label {
                    margin: auto;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .data {
            }
        }
    }
    .button-array {
        display: flex;
        margin-top: 20px;
        div {
            text-align: center;
            margin: auto;
            p {
                align-self: center;
                margin-right: 10px;
                font-size: 17px;
            }
            button {
                width: auto;
                margin-top: 10px;
                margin-right: 10px;
            }
        }
    }
}

.main-button {
    height: 39px;
    width: 100%;
    font-size: 16px;
    background: #30bf63 !important;
    color: #ffffff !important;
    text-transform: capitalize !important;
}

.login-heading {
    text-decoration: underline;
    font-size: 24px;
    margin-bottom: 40px;
    font-weight: 400;
}
